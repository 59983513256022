import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';

// Layout component
import Layout from '../components/layouts/Layout';
import HeaderMap from '../components/HeaderMap';
import BannerPicture from '../components/BannerPicture';
import CustomersLogos from '../components/CustomersLogos';
import TwoColumnsIcons from '../components/TwoColumnsIcons';
import CardCustomer from '../components/CardCustomer';
import BackgroundPicture from '../components/BackgroundPicture';
import SectionOnlyFooter from '../components/SectionOnlyFooter';

const TwoColumnsLayout = styled.div`
    margin: 7% 0%;
`;

const CardCustomerLayout = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content:flex-start;
    margin: 7% 0%;


    @media (max-width: 767px) {
        flex-wrap:nowrap;
        overflow-x: auto;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        -webkit-overflow-scrolling: touch;
        touch-action: pan-x;
    }
`;

const BgDarkBlue = styled.div`
    position:absolute;
    left:0;
    z-index: -10000;
    background-color: #0c499b;
    width: 100%;
    height: 43%;
`;

const LivemapIframe = styled.div`
    display: flex;
    width: 100%;
    height: 560px;
    border-radius: 10px;
    overflow: hidden;
    border: none;
    box-shadow: 3px 3px 18px 3px rgba(99,99,99,.16863);
`;

const CellLogo = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    flex-wrap:wrap;
    color:white;
    margin:7% 0%;
`;

const TitleMedium = styled.h3`
    font-size: 24px;
    font-weight: 300;
    text-align:center;
`;

const LogoLink = styled.a.attrs(({
    src, alt
}) => ({
    children: <img src={src}
        alt={alt}
        style={{width: '100%'}}/>,
    target: '_blank'
}))`
    width: 190px;
    padding: 20px;
`;

const IndexPage = ({ data }) => {
    const { t } = useTranslation('localAuthoritiesPage');

    const icons = [

        {

            icon: 'icon-people',
            text: t('DESCRIPTION_FESTIVALS')
        },

        {
            icon: 'icon-local_library',
            text: t('DESCRIPTION_CULTURAL_EVENTS')
        },
        {
            icon: 'icon-important_devices',
            text: t('DESCRIPTION_SPECIAL_OPS')
        },
        {
            icon: 'icon-laptop_windows',
            text: t('DESCRIPTION_MULTI_SITE')
        }];

    return (
        <React.Fragment>
            <Helmet defer={false}>
                <title>{t('META_TITLE_LOCAL')}</title>
                <meta name="description"
                    content={t('META_DESCRIPTION_LOCAL')}/>
            </Helmet>
            <Layout>
                <BackgroundPicture
                    image={data.background.childImageSharp}
                    alt='birdeye view of city'
                />
                <div className='wemap-page-index'>
                    <h1 className='wemap-page-index-title'
                        dangerouslySetInnerHTML={{ __html: t('TITLE') }}></h1>

                    <CustomersLogos
                        title={t('TITLE_BETTER_INFORM')}
                        size='medium'
                        customersLogos={[
                            {image: '/images/industries/local-authorities/logo-NGE.svg'},
                            {image: '/images/industries/local-authorities/cci-ile-de-france.svg'},
                            {image: '/images/industries/local-authorities/centre-val-de-loire.svg'},
                            {image: '/images/industries/local-authorities/nouvelle-aquitaine.svg'},
                            {image: '/images/industries/travel/365_houston_library.svg'},
                            {image: '/images/products/wemap-pro/region-haut-de-france.svg'},
                            {image: '/images/industries/local-authorities/region-paca.svg'},
                            {image: '/images/industries/local-authorities/planning-familial.svg'},
                            {image: '/images/industries/local-authorities/gard-conseil-general.svg'},
                            {image: '/images/industries/local-authorities/thonon-les-bains.svg'},
                            {image: '/images/industries/local-authorities/ile-de-france.svg'},
                            {image: '/images/industries/versailles-logo.png'}
                        ]}
                    />

                    <SectionOnlyFooter
                        titleSection={t('TITLE_PRATICAL_GUIDES')}
                        pictureRight={data.ipass.childImageSharp}
                        altDescription='mobiles with articles and a map open on screen'
                        titleButton={t('TITLE_BUTTON_TRY_OUT_NOW')}
                        urlFooter='/contact/'
                        iconsOnlyFooterComponent={[
                            {
                                icon: 'icon-news-paper',
                                text: t('TEXT_LOCAL_NEWS')
                            },
                            {
                                icon: 'icon-location22',
                                text: t('TEXT_DATA')
                            },
                            {
                                icon: 'icon-plane',
                                text: t('TEXT_ACTIVITIES_NEWS')
                            },
                            {
                                icon: 'icon-road',
                                text: t('TEXT_THEMATIC_GUIDES')
                            }
                        ]}
                    />

                    <TwoColumnsLayout>
                        <TwoColumnsIcons title={t('TITLE_PROJECTS_BIG_EVENTS')}
                            text={t('DESCRIPTION_MCC')}
                            titleButton={t('BUTTON_TALK_ABOUT_PROJECT')}
                            icons={icons}
                            leftIcon={data.mcc.childImageSharp}
                            altColumnLogo='Ministère de la Culture logo'
                            urlFooter='/contact/'
                        />
                    </TwoColumnsLayout>


                    <TwoColumnsLayout>
                        <TwoColumnsIcons title={t('TITLE_COMMUNICATION_CHANNELS')}
                            text={t('DESCRIPTION_WEMAP_JC_DECAUX')}
                            titleButton={t('BUTTON_TALK_ABOUT_PROJECT')}
                            leftIcon={data.jcd.childImageSharp}
                            altColumnLogo='JC Decaux logo'
                            icons={[
                                {
                                    icon: 'icon-web',
                                    text: t('DESCRIPTION_WEB_PORTAL')
                                },
                                {
                                    icon: 'icon-phone',
                                    text: t('DESCRIPTION_MOBILE_APPLICATIONS')
                                },
                                {
                                    icon: 'icon-fullscreen',
                                    text: t('DESCRIPTION_TOUCH_SCREENS')
                                },
                                {
                                    icon: 'icon-embed2',
                                    text: t('DESCRIPTION_MEDIAS_PARTNERS')
                                }
                            ]}
                            urlFooter='/contact/'
                        />
                    </TwoColumnsLayout>

                    <TitleMedium>{t('TECHNOLOGIE_WEMAP')}</TitleMedium>
                    <CellLogo rel="noopener">
                        <LogoLink
                            href='https://catalogue.numerique.gouv.fr/solutions/cartes-interactives-wemap-pro'
                            alt='Gouvernement logo'
                            src='/images/industries/local-authorities/gouvLogo.png'
                        />
                        <LogoLink
                            href='https://www.ugap.fr/'
                            alt='Ugap logo'
                            src='/images/industries/local-authorities/ugap.svg'
                        />
                    </CellLogo>

                    <BgDarkBlue></BgDarkBlue>
                    <HeaderMap customerCicleImageUrl='/images/industries/local-authorities/Icon_haut_de_france.png'
                        alt= 'Hauts de France logo'
                        titleCaseStudy={t('Case Study')}
                        title={t('TITLE_IPASS_SANTE')}
                        by={t('TEXT_BY')}
                        text={t('TITLE_DESCRIPTION_IPASS')}
                        textPointInfos={t('TITLE_POINTS_IPASS')}
                        textViewsInfos={t('TITLE_VIEWS_IPASS')}
                        descriptionCustomer={t('TITLE_GUIDE_GENERAL_PUBLIC')}
                        infosRight1={t('TITLE_DESCRIPTION_RIGHT_AF_0')}
                        infosRight2={t('TITLE_DESCRIPTION_RIGHT_AF_1')}
                        infosRight3={t('TITLE_DESCRIPTION_RIGHT_AF_2')} />


                    <LivemapIframe>
                        <iframe title="livemap"
                            width="100%"
                            height="100%"
                            src="https://livemap.getwemap.com/iframe.php?emmid=7334&token=QN0YWI0P98BBFG8299CBNMKUV"
                            allowfullscreen
                            frameborder="0"></iframe>
                    </LivemapIframe>


                    <CardCustomerLayout>

                        <CardCustomer
                            url='https://www.grandest.fr/agenda/sorties-nature/'
                            picturecard={data.grandest.childImageSharp}
                            altPictureCard='Grand Est logo'
                            title={t('TITLE_GRAND_EST')}
                            by={t('TEXT_BY')}
                            text={t('TITLE_GRAND_EST_BY')}
                            infosCard={[
                                {
                                    infoPoint: t('TITLE_GRAND_EST_POINT'),
                                    infoView: t('TITLE_GRAND_EST_VIEW')
                                }
                            ]}
                        />

                        <CardCustomer
                            url='https://www.hautsdefrance-propres.fr/'
                            picturecard={data.hautfrancelocal.childImageSharp}
                            altPictureCard='Haut de France logo'
                            title={t('TITLE_HAUT_FRANCE_LOCAL')}
                            by={t('TEXT_BY')}
                            text={t('TITLE_HAUT_FRANCE_LOCAL_BY')}
                            infosCard={[
                                {
                                    infoPoint: t('TITLE_HAUT_FRANCE_LOCAL_POINT'),
                                    infoView: t('TITLE_HAUT_FRANCE_LOCAL_VIEW')
                                }
                            ]}
                        />

                        <CardCustomer
                            url='https://getwemap.com/louvre'
                            picturecard={data.louvre.childImageSharp}
                            altPictureCard='Musée du Louvre logo'
                            title={t('TITLE_LOUVRE')}
                            by={t('TEXT_BY')}
                            text={t('TITLE_LOUVRE_BY')}
                            infosCard={[
                                {
                                    infoPoint: t('TITLE_LOUVRE_POINT'),
                                    infoView: t('TITLE_LOUVRE_VIEW')
                                }
                            ]}
                        />

                        <CardCustomer
                            url='https://www.nimes-stationnement.fr/'
                            picturecard={data.nimes.childImageSharp}
                            altPictureCard='Ville de Nîmes logo'
                            title={t('TITLE_NIMES')}
                            by={t('TEXT_BY')}
                            text={t('TITLE_NIMES_BY')}
                            infosCard={[
                                {
                                    infoPoint: t('TITLE_NIMES_POINT'),
                                    infoView: t('TITLE_NIMES_VIEW')
                                }
                            ]}
                        />

                        <CardCustomer
                            url='https://www.nge.fr/?cn-reloaded=1'
                            picturecard={data.nge.childImageSharp}
                            altPictureCard='NGE logo'
                            title={t('TITLE_NGE')}
                            by={t('TEXT_BY')}
                            text={t('TITLE_NGE_BY')}
                            infosCard={[
                                {
                                    infoPoint: t('TITLE_NGE_POINT'),
                                    infoView: t('TITLE_NGE_VIEW')
                                }
                            ]}
                        />

                        <CardCustomer
                            url='https://insidr.co/best-day-trips-outside-bordeaux/'
                            picturecard={data.insidr.childImageSharp}
                            altPictureCard='Insidr logo'
                            title={t('TITLE_INSIDR')}
                            by={t('TEXT_BY')}
                            text={t('TITLE_INSIDR_BY')}
                            infosCard={[
                                {
                                    infoPoint: t('TITLE_INSIDR_POINT'),
                                    infoView: t('TITLE_INSIDR_VIEW')
                                }
                            ]}
                        />

                        <CardCustomer
                            url='https://getwemap.com/proxima'
                            picturecard={data.proxima.childImageSharp}
                            altPictureCard='Proxima logo'
                            title={t('TITLE_PROXIMA')}
                            by={t('TEXT_BY')}
                            text={t('TITLE_PROXIMA_BY')}
                            infosCard={[
                                {
                                    infoPoint: t('TITLE_PROXIMA_POINT'),
                                    infoView: t('TITLE_PROXIMA_VIEW')
                                }
                            ]}
                        />

                        <CardCustomer
                            url='https://www.entreprises.cci-paris-idf.fr/j-aime-mes-commerces-a-domicile-adhesion-commercants'
                            picturecard={data.cci.childImageSharp}
                            altPictureCard='CCI logo'
                            title={t('TITLE_CCI')}
                            by={t('TEXT_BY')}
                            text={t('TITLE_CCI_BY')}
                            infosCard={[
                                {
                                    infoPoint: t('TITLE_CCI_POINT'),
                                    infoView: t('TITLE_CCI_VIEW')
                                }
                            ]}
                        />

                        <CardCustomer
                            url='https://www.ica.org/fr/quest-ce-que-les-archives/archives-et-documents-demeurent-accessibles-consultez-la-carte'
                            picturecard={data.ica.childImageSharp}
                            altPictureCard='J’aime mes commerces à domicile'
                            title={t('TITLE_ICA')}
                            by={t('TEXT_BY')}
                            text={t('TITLE_ICA_BY')}
                            infosCard={[
                                {
                                    infoPoint: t('TITLE_ICA_POINT'),
                                    infoView: t('TITLE_ICA_VIEW')
                                }
                            ]}
                        />

                        <CardCustomer
                            url='https://www.culture.gouv.fr/Aides-demarches/Protections-labels-et-appellations/Composants-Labels/MF/Carte-des-musees-de-France#'
                            picturecard={data.musee.childImageSharp}
                            altPictureCard='J’aime mes commerces à domicile'
                            title={t('TITLE_MUSEE')}
                            by={t('TEXT_BY')}
                            text={t('TITLE_MUSEE_BY')}
                            infosCard={[
                                {
                                    infoPoint: t('TITLE_MUSEE_POINT'),
                                    infoView: t('TITLE_MUSEE_VIEW')
                                }
                            ]}
                        />

                    </CardCustomerLayout>


                    <BannerPicture
                        backgroundBanner={data.banner.childImageSharp}
                        alt='Footer banner with sky view of city'
                        titleBannerPicture={t('TITLE_BANNER_CASE_STUDIES')}
                        titleButton={t('TITLE_BUTTON_TRY_OUT_NOW')}
                        buttonUrl='/contact/'
                    />

                </div>
            </Layout>
        </React.Fragment>
    );
};

IndexPage.propTypes = {data: PropTypes.object};

export default IndexPage;

export const query = graphql`{
  ipass: file(
    relativePath: {eq: "industries/local-authorities/ipass_contraception_livemap.png"}
  ) {
    childImageSharp {
      gatsbyImageData(quality: 75, width: 420, layout: FIXED)
    }
  }
  background: file(
    relativePath: {eq: "background-image/local-authorities-2-bg.png"}
  ) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  mcc: file(relativePath: {eq: "industries/media/logo_mcc.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, layout: FULL_WIDTH)
    }
  }
  jcd: file(relativePath: {eq: "industries/local-authorities/logo_jc_decaux.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, layout: FULL_WIDTH)
    }
  }
  hautfrancelocal: file(
    relativePath: {eq: "industries/local-authorities/card-horizontal-haut-de-france.png"}
  ) {
    childImageSharp {
      gatsbyImageData(quality: 75, layout: FULL_WIDTH)
    }
  }
  grandest: file(
    relativePath: {eq: "industries/local-authorities/card-horizontal-grand-est.png"}
  ) {
    childImageSharp {
      gatsbyImageData(quality: 75, layout: FULL_WIDTH)
    }
  }
  louvre: file(
    relativePath: {eq: "industries/local-authorities/picture_louvre.png"}
  ) {
    childImageSharp {
      gatsbyImageData(quality: 75, layout: FULL_WIDTH)
    }
  }
  nimes: file(
    relativePath: {eq: "industries/local-authorities/picture_nimes.png"}
  ) {
    childImageSharp {
      gatsbyImageData(quality: 75, layout: FULL_WIDTH)
    }
  }
  nge: file(relativePath: {eq: "industries/local-authorities/picture_nge.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, layout: FULL_WIDTH)
    }
  }
  insidr: file(relativePath: {eq: "industries/travel/picture_insidr.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, layout: FULL_WIDTH)
    }
  }
  proxima: file(relativePath: {eq: "industries/travel/picture_proxima.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, layout: FULL_WIDTH)
    }
  }
  cci: file(
    relativePath: {eq: "industries/local-authorities/card-horizontal-cci.png"}
  ) {
    childImageSharp {
      gatsbyImageData(quality: 75, layout: FULL_WIDTH)
    }
  }
  ica: file(
    relativePath: {eq: "industries/local-authorities/card-horizontal-ica.png"}
  ) {
    childImageSharp {
      gatsbyImageData(quality: 75, layout: FULL_WIDTH)
    }
  }
  musee: file(
    relativePath: {eq: "industries/local-authorities/card-horizontal-musee.png"}
  ) {
    childImageSharp {
      gatsbyImageData(quality: 75, layout: FULL_WIDTH)
    }
  }
  banner: file(
    relativePath: {eq: "industries/local-authorities/wemap_picture_local-authorities.png"}
  ) {
    childImageSharp {
      gatsbyImageData(quality: 75, layout: FULL_WIDTH)
    }
  }
}
`;
